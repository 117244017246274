import {
  DivCartaoSuperior,
  TextoNomeCidadao,
  TituloDocumento,
} from "../styles";
import styled from "styled-components";

export function CartaoSuperiorCPF({
  imagemDocumento,
  corFonte,
  usuario,
  tituloDocumento,
  nomeSocial,
}) {
  return (
    <DivCartaoSuperior imagemDocumento={imagemDocumento}>
      <TituloDocumento corFonte={corFonte}>{tituloDocumento}</TituloDocumento>
      <NomeSocial nomeSocial={nomeSocial} corFonte={corFonte} />
      <NomeCivil
        usuario={usuario}
        nomeSocial={nomeSocial}
        corFonte={corFonte}
      />
    </DivCartaoSuperior>
  );
}

const NomeSocial = ({ nomeSocial, corFonte }) => {
  if (!nomeSocial) return null;

  return (
    <NomeSocialWrapper $corFonte={corFonte}>
      <span className="text-down-01">Nome social:</span>
      <p className="text-down-01 text-uppercase">{nomeSocial}</p>
    </NomeSocialWrapper>
  );
};

const NomeCivil = ({ usuario, nomeSocial, corFonte }) => {
  if (!nomeSocial) {
    return (
      <TextoNomeCidadao className="text-down-01 text-uppercase" corFonte={corFonte}>
        {usuario.nome}
      </TextoNomeCidadao>
    );
  }

  return (
    <>
      <span className="text-down-01" style={{color: corFonte}}>Nome civil:</span>
      <p className="text-down-01 text-uppercase" style={{color: corFonte, paddingBottom: 'var(--spacing-scale-half)'}}>{usuario.nome}</p>
    </>
  );
};

const NomeSocialWrapper = styled.div`
  > span, p {
    color: ${props => props.$corFonte ? props.$corFonte : 'initial'};
  }
`;
